/**
 * External Dependencies
 */
import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import Masonry from 'react-masonry-css'
import shortid from 'shortid'
import TranslateText from './TranslateText'
import WordpressImage from './WordpressImage'
import PromoPanelInListing from './PromoPanelInListing'

const Tile = ({ post }) => {
  if (!post) {
    return <div />
  }

  const { uri, nodeType, title, featuredImage, mobileFeaturedImageUrl, featuredVideo, excerpt } =
    post

  if (nodeType === 'Promo_panel') {
    return (
      <div className="post-listing--item">
        <PromoPanelInListing post={post} />
      </div>
    )
  }

  const src = featuredImage?.node?.localFile?.publicURL
  const mobileSrc = mobileFeaturedImageUrl || ''
  const linkTo = uri || '/404'

  return (
    <div className="post-listing--item">
      <Link to={linkTo} itemProp="url">
        {!!featuredVideo?.length && (
          <video
            className="archive-video"
            autoPlay
            playsInline
            loop
            muted
            src={featuredVideo}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
        {!featuredVideo?.length && src && (
          <div className="wp-image">
            <WordpressImage preventReveal src={src} mobileSrc={mobileSrc} />
          </div>
        )}
        <span className="overline small">
          {nodeType && <TranslateText name={parse(nodeType)} />}
        </span>
        <h2 className="headline">{title && parse(title)}</h2>
        {excerpt && <div className="excerpt">{parse(excerpt)}</div>}
        <span className="small read-story">
          <TranslateText name="read_more" />
        </span>
      </Link>
    </div>
  )
}

// eslint-disable-next-line react/function-component-definition
export default function ArchiveFeatures({ posts }) {
  const tiles = posts.map(post => <Tile key={shortid.generate()} post={post} />)

  const breakpointColumnsObj = {
    default: 2,
    767: 1,
  }

  return (
    <div className="container">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {tiles}
      </Masonry>
    </div>
  )
}
