/**
 * External Dependencies
 */
import React from 'react'
import PromoPanel from './PromoPanel'

// eslint-disable-next-line react/function-component-definition
export default function PromoPanelInListing({ post }) {
  const { promoPanelTextColor, promoPanelBgColor } = post

  const panelClassName = 'promo-panel'
  const cssStyles = {
    color: promoPanelTextColor,
    backgroundColor: promoPanelBgColor,
  }

  return (
    <div className={panelClassName} style={cssStyles}>
      <PromoPanel post={post} />
    </div>
  )
}
