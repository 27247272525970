/**
 * External Dependencies
 */
import React from 'react'
import classNames from 'classnames'
import sanitizeHtml from 'sanitize-html'
import WordpressImage from './WordpressImage'

// eslint-disable-next-line react/function-component-definition
export default function PromoPanel({ post }) {
  const src = post.featuredImage?.node?.localFile?.publicURL

  const {
    promoPanelLayout,
    promoPanelTitle,
    promoPanelBody,
    promoPanelButton,
    promoPanelButtonUrl,
    promoPanelButtonNewWindow,
    promoPanelTextColor,
    promoPanelBgColor,
    databaseId,
  } = post

  const newWindowTarget = promoPanelButtonNewWindow ? '_blank' : ''
  const newWindowRel = promoPanelButtonNewWindow ? 'noreferrer' : ''

  const buttonStyle = {
    borderColor: promoPanelTextColor,
  }

  const promoPanelId = `promo-panel-${databaseId}`
  const buttonHoverBgColor = promoPanelTextColor
  const buttonHoverTextColor = promoPanelBgColor

  const isLayout1Col = promoPanelLayout === 'layout-1-col'
  const isLayout2Col = promoPanelLayout === 'layout-2-col'
  const isLayout2ColWide = promoPanelLayout === 'layout-2-col-wide'
  const isLayout3ColWide = promoPanelLayout === 'layout-3-col-wide'

  return (
    <div
      id={promoPanelId}
      className={classNames(
        promoPanelLayout,
        isLayout1Col ? 'row' : '',
        isLayout2Col ? 'row' : '',
        isLayout2ColWide ? 'row' : '',
        isLayout3ColWide ? 'row' : ''
      )}
    >
      <style>{`
          #${promoPanelId} .wp-block-button__link:hover {
            color: ${promoPanelBgColor};
            background-color: ${promoPanelTextColor};
          }
        `}</style>
      <div
        className={classNames(
          'panel-image',
          isLayout1Col ? 'col-12' : '',
          isLayout2Col ? 'col-md-12 col-lg-6' : '',
          isLayout2ColWide ? 'col-xs-12 col-sm-6 col-md-6' : '',
          isLayout3ColWide ? 'col-sm-6 col-md-6 col-lg-4' : ''
        )}
      >
        <WordpressImage preventReveal src={src} />
      </div>

      <div
        className={classNames(
          'panel-content',
          isLayout1Col ? 'col-12' : '',
          isLayout2Col ? 'col-md-12 col-lg-6' : '',
          isLayout2ColWide ? 'col-xs-12 col-sm-6 col-md-6' : '',
          isLayout3ColWide ? 'col-sm-6 col-md-6 col-lg-5' : ''
        )}
      >
        <h3>{promoPanelTitle}</h3>

        <div
          className="panel-body"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(promoPanelBody, {
              allowedAttributes: {
                a: ['href', 'class'],
                span: ['class'],
              },
            }),
          }}
        />

        <div className="wp-block-button is-style-outline">
          <a
            href={promoPanelButtonUrl}
            className="wp-block-button__link"
            data-hover-text-color={buttonHoverTextColor}
            data-hover-bg-color={buttonHoverBgColor}
            style={buttonStyle}
            target={newWindowTarget}
            rel={newWindowRel}
          >
            {promoPanelButton}
          </a>
        </div>
      </div>

      {isLayout3ColWide && (
        <div className="panel-buttons col-lg-3">
          <div className="wp-block-button is-style-outline">
            <a
              href={promoPanelButtonUrl}
              className="wp-block-button__link"
              data-hover-text-color={buttonHoverTextColor}
              data-hover-bg-color={buttonHoverBgColor}
              style={buttonStyle}
              target={newWindowTarget}
              rel={newWindowRel}
            >
              {promoPanelButton}
            </a>
          </div>
        </div>
      )}
    </div>
  )
}
